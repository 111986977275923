<template>
    <div class="slide_box" :class="{'active' : modelValue}">
        <div class="box_container">
            <button class="close_btn" @click="$emit('update:modelValue', false);"><i class="fas fa-times"></i></button>
            <div class="instructor" v-if="selectedPlaybook.display_coach">
                <h3 :style="`color: ${selectedPlaybook.title_text_color};`">{{ selectedPlaybook.coach_headline }}</h3>
                <div class="user_box">
                    <img :src="selectedPlaybook.coach_image ? selectedPlaybook.coach_image : require('@/assets/images/customer.svg')" alt="">
                    <div class="user_info">
                        <h3>{{ selectedPlaybook.coach_name }}</h3>
                        <h5>{{ selectedPlaybook.coach_title }}</h5>
                    </div>
                </div>
                <p v-html="selectedPlaybook.coach_bio"></p>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: "Instructor",

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        computed: mapState({
            // user: state => state.authModule.user,
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
        })
    }
</script>

<style scoped>
.slide_box{
  padding: 30px;
  border-radius: 20px 20px 0 0;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: -100%;
  width: 100%;
  max-height: 430px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 -2px 30px rgba(0, 0, 0, 0.15);
  text-align: left;
  z-index: 3;
}
.slide_box.active{
  bottom: 0;
}
.close_btn {
    border: 0;
    background: rgba(50,55,59,.6);
    font-size: 12px;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
</style>